<template>
  <div id="kontakt">
    <div id=image-holder2>
      <h1>Kontakt</h1>
    </div>
    
    <div id="kontakt-sadrzaj">
      <iframe id="mapa"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2826.0047280183203!2d20.286606415538696!3d44.90289967909824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a5d4b94c346e1%3A0x331610736506b284!2sKontoherb%20Knjigovodstvena%20agencija!5e0!3m2!1ssr!2srs!4v1613597451817!5m2!1ssr!2srs" loading="lazy" width="450" height="500" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      <div id="kontakt-kartica">
        <div> <h1><b> Knjigovodstvena agencija <br> Kontoherb </b></h1></div>
        <div> <span class="fa fa-map-marker-alt"></span> <p>Bosanske Krajine 41<br>11273 Beograd </p> </div>
        <div> <span class="fab fa-facebook"></span> <a href="https://sr-rs.facebook.com/agencija.tsfinanc.5" target="_blank"><p>Knjigovodstvena Agencija Kontoherb </p></a> </div>
        <div> <span class="fab fa-instagram"></span> <a href="https://www.instagram.com/knjigovodstvo_kontoherb/" target="_blank"><p>knjigovodstvo_kontoherb</p></a> </div>
        <div> <span class="fa fa-envelope"></span> <a href="mailto:office@kontoherb.com"><p>office@kontoherb.com</p> </a></div>
        <div> <span class="fa fa-phone-alt"></span> <p>069 55 444 28 <br> 011 37 325 02</p> </div>
      </div>
    </div>
    <div style="  background-color: #dcdcdc;">
      <div  class="naslov" id="kontakt-forma-p"><h2>Pošaljite nam poruku</h2></div>
      <div id="kontakt-forma">
        <input v-model="ime" @click="skiniGresku()" name="ime" type="text" placeholder="Ime">
        <input v-model="email" @click="skiniGresku()" name="email" type="text" placeholder="E-mail">
        <textarea name="poruka" @click="skiniGresku()" v-model="poruka" placeholder="Poruka" rows="12"></textarea>
        <p v-if="poslato" id="poslato">Poruka je poslata!</p>
        <p v-if="greska" class="greska">{{tekstGreske}}</p>
        <!-- <p v-if="greska2" class="greska">Došlo je do greške pri slanju, pokušajte ponovo</p> -->
        <div><button class="dugme" @click="submit()" >Pošalji</button></div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from '@/axios';

export default {
  name: 'Kontakt',
  components: {

  },
  data: function(){
    return(
      {
        ime: "",
        email: "",
        poruka: "",
        poslato: false,
        greska: false,
        tekstGreske: ""
      }
    );
  },
  methods:{
    submit(){
      var self=this;
      this.poslato=false;
      this.greska=false;
      if(this.email=="" || this.ime=="" || this.poruka=="")
      {
        this.greska=true;
        this.tekstGreske="Unesite sve podatke";
        return;
      }
      if(!this.validateEmail())
      {
        this.greska=true;
        this.tekstGreske="Unesite ispravnu mejl adresu";
        return;
      }
      let params = new URLSearchParams();
      params.append('ime', this.ime);
      params.append('email', this.email );
      params.append('poruka', this.poruka );
      axios.post('kontakt-forma.php', params).then(function (response) {
        console.log("POSLATO");
        console.log("OK! ", response,response.data);  
        self.poslato=true;
        self.ime=self.email=self.poruka="";
      })
      .catch(function () {
        console.log("ERROR");
        self.greska=true;
        self.tekstGreske="Došlo je do greške pri slanju, pokušajte ponovo";
      });
    },
    skiniGresku(){
      this.greska=false;
    },
    validateEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.email).toLowerCase());
    }
  },
  mounted(){
    
  }
}
</script>

<style scoped>

#image-holder2{
  opacity:0.7;
  background-image: url("../assets/kontakt.jpg");
  background-position: bottom;
  background-size:cover;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
}


#kontakt-forma{
  margin-top:40px;
  padding:20px;
  display:flex;
  flex-direction: column;
  align-items: center;
}



.dugme{
  margin-top:30px;
}

#kontakt-sadrzaj{
  padding:100px 5% 100px 5%;
  display:flex;
  justify-content:center;
  flex-wrap: wrap;
  background-color: #dcdcdc;
}

#poslato{
  border-style: solid;
  border-width: 2px;
  border-color: green;
  /* background-color: white; */
  border-radius: 10px;
  font-size: 25px;
  width:60%;
  margin-bottom:0;
  padding:5px;
}

.greska{
  border-style: solid;
  border-width: 2px;
  /* border-color: red; */
  color:white;
  background-color: rgb(228, 92, 92);
  border-radius: 10px;
  font-size: 25px;
  width:60%;
  margin-bottom:0;
  padding:5px;
}

#mapa{
  width:40%;
}

#kontakt-kartica{
  display:flex;
  flex-direction: column;
  justify-content: center;
  width:40%;
  height:500px;
  margin-left:10%;
  /* background-color: white; */
  font-size:25px;
}

#kontakt-kartica div{
  display: flex;
  justify-content: flex-start;
  padding:10px;
  /* line-height: 55px; */
}

#kontakt-kartica div svg{
  margin:10px;
  margin-bottom: 5px;
  font-size:30px;
}

#kontakt-kartica div p{
  text-align:left;
  margin-left:20px;
  margin-bottom: 0;
  margin-top: 6px;
}

#kontakt-kartica a{
  color: #2c3e50;
}

@media screen and (max-width: 1000px) {

  #kontakt-sadrzaj{
    flex-direction: column;
  }

  #kontakt-kartica{
    margin-top:25px;
    margin-left:0;
    font-size:22px;
    width:100%;
    height:100%;
  }

  #mapa{
    width:100%;
  }
}

</style>